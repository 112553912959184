import React from "react";
import ProductsOne from "../../components/ProductsOne";

const Products = () => {
  return (
    <div>
      <ProductsOne />
    </div>
  );
};

export default Products;
