import React from 'react'
import GalleryOne from '../../components/GalleryOne'

const Gallery = () => {
  return (
    <div>
      <GalleryOne/>
    </div>
  )
}

export default Gallery
