import React from "react";
import ContactForm from "../../components/ContactForm";
import MapOne from "../../components/MapOne";
import FooterTwo from "../../components/FooterTwo";


const Contact = () => {
  return (
    <div>
      <ContactForm />
      <MapOne />
      
    
    </div>
  );
};

export default Contact;
