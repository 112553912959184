import React from 'react'

export default function SinglePage() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-2">HOW TO SHOW COMPASSION</h1>
      <p className="text-sm text-gray-600 mb-6">17 May 2019 - By Andrew Johnson</p>
      
      <img 
        src="https://placehold.co/600x400" 
        alt="Person speaking to a group" 
        className="w-full h-64 object-cover mb-6"
      />
      
      <h2 className="text-xl font-semibold mb-4">LOREM IPSUM DOLOR SIT AMET</h2>
      <p className="mb-6">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      </p>
      
      <img 
        src="https://placehold.co/600x400" 
        alt="Children working together" 
        className="w-full h-48 object-cover mb-6"
      />
      
      <h2 className="text-xl font-semibold mb-4">LOREM IPSUM DOLOR SIT AMET</h2>
      <p className="mb-6">
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </p>
      
      <blockquote className="border-l-4 border-gray-500 pl-4 italic mb-6">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </blockquote>
      
      <h2 className="text-xl font-semibold mb-4">LOREM IPSUM DOLOR SIT AMET CONSECTETUR</h2>
      <p className="mb-4">
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      
      <ul className="list-disc pl-6 mb-6">
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
        <li>Excepteur sint occaecat cupidatat non proident</li>
        <li>Ut enim ad minim veniam, quis nostrud exercitation</li>
      </ul>
      
      <p className="mb-6">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      </p>
    </div>
  )
}