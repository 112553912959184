import React from "react";

import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "./Footer";
import Header from "./header/Header";

// import RoundCategoryCarousel from './RoundCategoryCarousel';

const Layout = () => {
  return (
    <>
      {/* <RoundCategoryCarousel/> */}

      <Header />

      <Outlet />
      <Footer />

      <ToastContainer
        oastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Layout;
