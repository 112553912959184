import React, { useEffect, useState } from "react";
import axios from "axios";

export default function CompanyLogoOne() {
  const [logos, setLogos] = useState([]); // State for logos
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error handling

  // Get the API base URL from environment variables
  const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

  useEffect(() => {
    // Fetch client logos from the API
    const fetchLogos = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/client-logo`); // Using environment variable for the base URL
        setLogos(response.data || []);
      } catch (error) {
        console.error("Error fetching client logos:", error);
        setError("Failed to load client logos.");
      } finally {
        setLoading(false);
      }
    };

    fetchLogos();
  }, [API_BASE_URL]);

  // Handle loading state
  if (loading) {
    return <p className="text-center text-gray-500">Loading client logos...</p>;
  }

  // Handle error state
  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-center text-blue-600 text-sm uppercase tracking-wide mb-4">
        Trusted By Leading Companies
      </h2>

      {/* Logo Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {logos.map((logo) => (
          <div
            key={logo._id}
            className="border border-gray-200 p-4 flex items-center justify-center bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
          >
       <img
  src={`${API_BASE_URL}/uploads/main/${logo.img?.replace(/\\/g, "/")}`} // Normalize path
  alt={logo.name ? `${logo.name} Logo` : "Client Logo"} // Improved alt text
  className="max-w-full max-h-16 object-contain"
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder-logo.webp"; // Fallback to a placeholder image
  }}
  crossorigin="anonymous" // Ensure compatibility for cross-origin requests
/>

          </div>
        ))}
      </div>
    </div>
  );
}
