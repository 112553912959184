import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPageBySlug } from "../../api/pageApi";
import TableDisplay from "./TableDisplay";
import OtherProducts from "./OtherProducts";
import "./DetailPage.css";
import { FaWhatsapp } from "react-icons/fa";

const BASE_URL = process.env.REACT_APP_BACKEND_API_URL ;


const DetailPage = () => {
  const { slug } = useParams(); // Retrieve slug from URL
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);

  useEffect(() => {
    const fetchPage = async () => {
      try {
        setLoading(true);
        const data = await getPageBySlug(slug); // Fetch the page data using the slug
        setPageData(data);
      } catch (err) {
        console.error("Error fetching page data:", err);
        setError("Failed to load page content.");
      } finally {
        setLoading(false);
      }
    };

    fetchPage();
  }, [slug]);

  if (loading)
    return <div className="text-center text-gray-600 loading">Loading...</div>;
  if (error)
    return <div className="text-center text-red-600 error">{error}</div>;
  if (!pageData)
    return <div className="text-center text-gray-600 not-found">Page not found</div>;

  const imageUrls = pageData.images?.map((image) => `${BASE_URL}/uploads/${image}`) || [];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Main Content */}
        <div className="lg:col-span-2">
          <article className="space-y-6">
            <header className="space-y-2">
              <h1 className="text-3xl font-bold tracking-tight">
                {pageData.name || "Untitled Page"}
              </h1>
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <span>{pageData.author || "Admin"}</span>
              </div>
            </header>

            {/* Thumbnail */}
            {pageData.thumbnail && (
      <img
      src={
        pageData.thumbnail
          ? `${BASE_URL}/uploads/${pageData.thumbnail.replace(/\\/g, "/")}`
          : "/default-placeholder.webp" // Fallback placeholder image
      }
      alt={pageData.title || "Page Thumbnail"} // Use the page title as the alt text
      className="w-full rounded-lg object-cover aspect-video"
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite loop
        e.target.src = "/default-placeholder.webp"; // Fallback to placeholder image
      }}
      crossorigin="anonymous" // Handle cross-origin requests
    />
    
            )}

            {/* Description */}
            {pageData.description && (
              <div
                className="prose max-w-none"
                dangerouslySetInnerHTML={{ __html: pageData.description }}
              />
            )}

            {/* Images */}
            {pageData.images && pageData.images.length > 0 && (
              <div className="images-section">
                <h2 className="text-xl font-semibold">Gallery</h2>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {pageData.images.map((image, index) => (
          
          <img
  key={index}
  src={`${BASE_URL}/uploads/${image.replace(/\\/g, "/")}`}
  alt={`Image ${index + 1} - Click to view larger`} // Improved alt text for accessibility
  className="w-full h-auto rounded-lg object-cover cursor-pointer"
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder.webp"; // Fallback to a placeholder image
  }}
  onClick={() => {
    setModalImageIndex(index); // Set the clicked image index
    setIsModalOpen(true); // Open the modal
  }}
  crossorigin="anonymous" // Ensure compatibility for cross-origin image loading
/>

                  ))}
                </div>
              </div>
            )}

            {/* Table Data */}
            {pageData.tableData && pageData.tableData.length > 0 && (
              <div className="table-section">
                <h2 className="text-xl font-semibold">Table</h2>
                <TableDisplay tableData={pageData.tableData} />
              </div>
            )}



{/* Main Content */}

    {/* ...Other Content... */}
    {/* Contact and WhatsApp Buttons */}

    <div className="flex flex-row space-x-4 mt-4">
  <button
    onClick={() => (window.location.href = "/contact")}
    className="flex-1 bg-blue-600 text-white px-4 py-2 rounded font-medium hover:bg-blue-700 transition-colors"
  >
    Contact Us
  </button>
  <button
    onClick={() => window.open("https://wa.me/9443117233", "_blank")}
    className="flex-1 bg-green-500 text-white px-4 py-2 rounded font-medium hover:bg-green-600 transition-colors flex items-center justify-center gap-2"
  >
    <FaWhatsapp className="w-5 h-5" />
    WhatsApp
  </button>
</div>






            
          </article>
        </div>

        {/* Sidebar */}
        <aside className="space-y-8">
          <OtherProducts />
     
        </aside>
      </div>

      {/* Modal */}
  {/* Modal */}
{isModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
    {/* Close Button */}
    <button
      className="absolute top-4 right-4 text-white text-4xl font-bold"
      onClick={() => setIsModalOpen(false)}
    >
      &times;
    </button>

    {/* Previous Image Button */}
    <button
      className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-3xl font-bold"
      onClick={() =>
        setModalImageIndex(
          (modalImageIndex + imageUrls.length - 1) % imageUrls.length
        )
      }
    >
      &#8249;
    </button>

    {/* Image Display */}
    <img
  src={imageUrls[modalImageIndex]}
  alt={`Modal Image ${modalImageIndex + 1} - Enlarged View`} // Improved alt text for accessibility
  className="max-w-[90%] max-h-[90%] rounded-lg shadow-lg object-contain"
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder.webp"; // Fallback to a placeholder image
  }}
  crossorigin="anonymous" // Ensure compatibility for cross-origin image loading
/>


    {/* Next Image Button */}
    <button
      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-3xl font-bold"
      onClick={() =>
        setModalImageIndex((modalImageIndex + 1) % imageUrls.length)
      }
    >
      &#8250;
    </button>
  </div>
)}

    </div>
  );
};

export default DetailPage;
