import React from "react";
import IndustriesList from "../../components/IndustriesList";

const IndustriesWeServe = () => {
  return (
    <div>
      <IndustriesList />
    </div>
  );
};

export default IndustriesWeServe;
