import React from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa"; // Import social media icons

export default function FooterTwo() {
  return (
    <footer className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300 pt-16 pb-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          {/* Left Column */}
          <div className="w-full md:w-1/3 mb-8 md:mb-0">
            <img
              src="./images/logo.png"
              alt="Able Tech Engineering Logo"
              className="mb-4"
              style={{ height: "80px", width: "130px" }}
            />
            <h2 className="text-3xl font-bold mb-2 text-gray-800">
              Let's make<br />something special
            </h2>
            <p className="mb-4 text-gray-600">Let's talk!</p>
            <p className="mb-2 text-gray-600">+91-94431-17233</p>
            <p className="mb-2 text-gray-600">info@abletechengineering.com</p>
            <p className="text-gray-600">
              S.F.NO.-483/1A2, #19, Vadivu Nagar,<br />
              Chettipalayam Road, Vellalore PO,<br />
              Coimbatore, Tamil Nadu, India - 641111
            </p>
          </div>

          {/* Right Column */}
          <div className="w-full md:w-2/3 flex flex-wrap">
            <div className="w-1/2 sm:w-1/4 mb-8">
              <h3 className="font-semibold mb-4 text-gray-800">Company</h3>
              <ul>
                <li className="mb-2">
                  <a href="/about-us" className="text-gray-600 hover:text-gray-900">About Us</a>
                </li>
                <li className="mb-2">
                  <a href="/products" className="text-gray-600 hover:text-gray-900">Products</a>
                </li>
                <li className="mb-2">
                  <a href="/clients" className="text-gray-600 hover:text-gray-900">Clients</a>
                </li>
                <li>
                  <a href="/contact" className="text-gray-600 hover:text-gray-900">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="w-1/2 sm:w-1/4 mb-8">
              <h3 className="font-semibold mb-4 text-gray-800">Services</h3>
              <ul>
                <li className="mb-2">
                  <a href="/designing-facilities" className="text-gray-600 hover:text-gray-900">Heat Exchanger</a>
                </li>
                <li className="mb-2">
                  <a href="/manufacturing-facility" className="text-gray-600 hover:text-gray-900">Oil Cooler</a>
                </li>
                {/* <li className="mb-2">
                  <a href="/quality-assurance" className="text-gray-600 hover:text-gray-900">Quality Assurance</a>
                </li> */}
                {/* <li>
                  <a href="/after-sales-support" className="text-gray-600 hover:text-gray-900">After Sales Support</a>
                </li> */}
              </ul>
            </div>
            <div className="w-1/2 sm:w-1/4 mb-8">
              <h3 className="font-semibold mb-4 text-gray-800">Resources</h3>
              <ul>
                {/* <li className="mb-2">
                  <a href="/literature" className="text-gray-600 hover:text-gray-900">Literature</a>
                </li> */}
                <li className="mb-2">
                  <a href="/gallery" className="text-gray-600 hover:text-gray-900">Gallery</a>
                </li>
                {/* <li className="mb-2">
                  <a href="/testimonials" className="text-gray-600 hover:text-gray-900">Testimonials</a>
                </li>
                <li>
                  <a href="/faq" className="text-gray-600 hover:text-gray-900">FAQ</a>
                </li> */}
              </ul>
            </div>
            <div className="w-full sm:w-1/4 flex items-end">
              <a
                href="/contact"
                className="bg-blue-600 text-white px-6 py-3 rounded-full inline-flex items-center"
              >
                Contact Us
                <svg
                  className="w-4 h-4 ml-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-200 mt-16 pt-8 flex flex-col sm:flex-row justify-between items-center">
          <p className="text-gray-600 mb-4 sm:mb-0">
            &copy; {new Date().getFullYear()} Able Tech Engineering
          </p>
          <div className="flex space-x-4">
            {/* Social Media Icons */}
            <a
              href="https://www.linkedin.com/company/able-tech-engineering"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700 hover:text-blue-900 transition"
            >
              <FaLinkedinIn size={24} />
            </a>
            <a
              href="https://www.facebook.com/abletechengineering"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700 transition"
            >
              <FaFacebookF size={24} />
            </a>
            <a
              href="https://twitter.com/abletecheng"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-600 transition"
            >
              <FaTwitter size={24} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
