import React, { useState } from 'react';

export default function PopupOne() {
  const [isOpen, setIsOpen] = useState(true);
  const [mobileNumber, setMobileNumber] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Mobile Number:', mobileNumber);
    console.log('Agreed to Terms:', agreeTerms);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center p-4 animate-fadeIn">
      <div className="bg-white rounded-lg shadow-2xl w-full max-w-md transition-transform transform-gpu scale-95 hover:scale-100">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <img src="https://placehold.co/120x40" alt="Justdial" className="h-10" />
            <button onClick={() => setIsOpen(false)} className="text-gray-400 hover:text-gray-600">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <h2 className="text-2xl font-semibold mb-2">Welcome</h2>
          <p className="text-gray-600 mb-6">Login for a seamless experience</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  +91
                </span>
                <input
                  type="tel"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  className="flex-1 block w-full rounded-none rounded-r-md sm:text-sm focus:ring focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
                  placeholder="Enter Mobile Number*"
                />
              </div>
            </div>
            <div className="mb-6">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={agreeTerms}
                  onChange={(e) => setAgreeTerms(e.target.checked)}
                  className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
                <span className="text-sm text-gray-600">
                  I Agree to Terms and Conditions
                </span>
              </label>
              <p className="text-xs text-gray-500 mt-1">T&C's Privacy Policy</p>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white rounded-md py-2 px-4 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150 ease-in-out"
            >
              Login with OTP
            </button>
          </form>
          <p className="text-center text-sm text-gray-500 mt-4">
            <button onClick={() => setIsOpen(false)} className="hover:underline">
              Maybe Later
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

