import React, { useEffect, useState } from "react";
import axios from "axios";

// Use the base URL from the environment variables
const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;
const placeholderImage = "/default-placeholder.webp"; // Path to a placeholder image in your public folder

export default function SectionOne() {
  const [section, setSection] = useState(null); // State to hold section data
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch Section One content from the backend
  useEffect(() => {
    const fetchSectionOne = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/section-one`);
        setSection(response.data);
      } catch (error) {
        console.error("Error fetching Section One content:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSectionOne();
  }, []);

  // Loader
  if (loading) {
    return (
      <div className="text-center py-8">
        <svg
          className="animate-spin h-10 w-10 text-blue-500 mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
        <p className="mt-4 text-gray-500">Loading Section One content...</p>
      </div>
    );
  }

  // Handle case where no section content is found
  if (!section) {
    return (
      <div className="text-center py-8 text-gray-500">
        Section One content not available.
      </div>
    );
  }

  // Handle case where `img`, `title`, or `description` might be missing
  const imageSrc = section.img
    ? `${BASE_URL}/uploads/main/${section.img.replace(/\\/g, "/")}`
    : placeholderImage;

  return (
    <div className="bg-gradient-to-br from-gray-800 via-blue-900 to-gray-900 py-20 px-4 sm:px-8">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center gap-12">
        {/* Image Section */}
        <div className="md:w-1/2">
          <img
            src={imageSrc}
            alt={section.title || "Section One"}
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = placeholderImage; // Fallback to placeholder image
            }}
            crossOrigin="anonymous" // Allow cross-origin resource fetching
            className="rounded-lg shadow-lg w-full"
          />
        </div>
        {/* Content Section */}
        <div className="md:w-1/2 space-y-6">
          <h2 className="text-blue-300 text-lg font-semibold">
            {section.subtitle || "Subtitle"}
          </h2>
          <h1 className="text-4xl font-bold text-white leading-tight">
            {section.title || "Section Title"}
          </h1>
          <p className="text-gray-300 text-lg">
            {section.description || "Section description goes here."}
          </p>
        </div>
      </div>
    </div>
  );
}
