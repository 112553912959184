import axios from "../utils/axiosConfig"; // Importing the Axios instance as axiosInstance
import { config } from "../utils/axiosConfig"; // Importing config for headers

// Function to fetch a single page by ID
// src/api/pageApi.js


// Function to fetch all pages
export const fetchAllPages = async () => {
  try {
    const response = await axios.get('/page/all');
    return response.data;
  } catch (error) {
    console.error('Error fetching pages:', error);
    throw error;
  }
};




// Fetch a single page by slug
export const getPageBySlug = async (slug) => {
  if (!slug) {
    console.error("Slug is undefined in getPageBySlug");
    throw new Error("Slug is undefined");
  }

  try {
    const response = await axios.get(`/api/page/${slug}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching page by slug:", error);
    throw error;
  }
};

