import React from 'react';
import { FiPhoneCall } from 'react-icons/fi'; // Import phone icon

export default function CallToActionOne() {
  return (
    <div className="bg-gray-100 flex items-center justify-center px-4 py-8">
      <div className="w-full max-w-4xl relative">
        {/* Red square */}
        <div className="absolute -top-2 -left-2 w-4 h-4 bg-red-500"></div>

        {/* Blue CTA box */}
        <div className="bg-blue-600 text-white p-8 md:p-12 rounded-lg shadow-lg flex flex-col md:flex-row items-center justify-between">
          <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-0 text-center md:text-left">
            Let’s Discuss Your Engineering Needs Today
          </h2>
          <a
            href="tel:+919443117233" // Replace with your company's phone number
            className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded flex items-center gap-2 transition duration-300"
          >
            <FiPhoneCall className="text-lg" /> Contact Us
          </a>
        </div>

        {/* Blue square */}
        <div className="absolute -bottom-2 -right-2 w-4 h-4 bg-blue-500"></div>
      </div>
    </div>
  );
}
