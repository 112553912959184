import React from 'react';

export default function OverseasPartners() {
  const countries = [
    'USA', 'CANADA', 'MALAYSIA', 'GERMANY', 'RUSSIA', 'IRAN',
    'YEMEN', 'MOROCCO', 'SRI LANKA', 'UAE', 'JORDAN', 'PHILIPPINES',
  ];

  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Header Section */}
      <div className="relative mb-12">
        <div className="bg-gradient-to-r from-blue-500 to-green-500 text-white text-center text-3xl font-bold py-4 px-8 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-200">
          🌍 OVERSEAS PARTNERS
        </div>
      </div>

      {/* Globe Image Section */}
      <div className="flex justify-center mb-8">
        <div className="relative w-full max-w-md">
          <div
            className="bg-gradient-to-r from-blue-400 to-purple-500 flex items-center justify-center rounded-lg shadow-md h-64"
          >
            <img
              src="https://img.icons8.com/ios-filled/200/ffffff/globe.png"
              alt="Globe Icon"
              className="w-36 h-36"
            />
          </div>
        </div>
      </div>

      {/* Description Text */}
      <div className="text-gray-800 mb-8 text-center">
        <p className="mb-6">
          Abletech has successfully exported its products worldwide for the past ten years,
          serving various clients across numerous industries.
        </p>
        <p className="font-semibold mb-4">Some of the countries we export to are listed below:</p>
      </div>

      {/* Countries List */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {countries.map((country, index) => (
          <div
            key={index}
            className="flex items-center space-x-2 text-gray-700"
          >
            <span className="text-blue-500 text-lg font-bold">➤</span>
            <span className="font-medium">{country}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
