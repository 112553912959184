import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BACKEND_API_URL; // Base URL from environment variables
const placeholderImage = '/default-placeholder.webp'; // Path to a placeholder image in your public folder

export default function CardSectionOne() {
  const [products, setProducts] = useState([]); // State for product data
  const [loading, setLoading] = useState(true); // State for loading

  // Fetch products from backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log('Base URL:', BASE_URL); // Log the base URL

        const apiUrl = `${BASE_URL}/api/page/all`;
        console.log('API URL:', apiUrl); // Log the API URL

        const response = await axios.get(apiUrl);
        console.log('API Response:', response.data); // Log the response data

        // Ensure response data is an array
        const productsData = Array.isArray(response.data)
          ? response.data.map((product) => ({
              name: product.name || 'Unnamed Product',
              image: product.thumbnail
                ? `${BASE_URL}/uploads/${product.thumbnail.replace(/\\/g, '/')}`
                : placeholderImage,
              slug: product.slug || '#',
            }))
          : [];

        setProducts(productsData); // Set the processed data
      } catch (error) {
        console.error('Error fetching products:', error); // Log any errors
      } finally {
        setLoading(false); // Ensure loading is set to false
      }
    };

    fetchProducts();
  }, []);

  // Limit the number of products displayed
  const limitedProducts = products.slice(0, 10);

  // Display loading spinner
  if (loading) {
    return (
      <div className="text-center py-8">
        <svg
          className="animate-spin h-10 w-10 text-blue-500 mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
      </div>
    );
  }

  // Display component
  return (
    <div className="bg-gradient-to-b from-gray-50 via-gray-100 to-gray-200 py-12">
      {/* Subtle gradient background */}
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold text-center mb-10 text-gray-800">
          PRODUCT PREVIEW
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
          {limitedProducts.map((product, index) => (
            <Link
              to={`/${product.slug}`}
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
            >
              <img
                src={product.image}
                alt={product.name}
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src = placeholderImage; // Fallback to placeholder image
                }}
                className="w-full h-48 object-cover"
                crossOrigin="anonymous" // Allow cross-origin resource fetching
              />

              <div className="p-4">
                <h2 className="text-lg font-semibold text-center text-gray-700">
                  {product.name}
                </h2>
              </div>
            </Link>
          ))}
        </div>
        <div className="text-center mt-8">
          <a
            href="/products"
            className="bg-blue-500 text-white font-semibold py-2 px-8 rounded-lg hover:bg-blue-600 transition-colors duration-300"
          >
            View More
          </a>
        </div>
      </div>
    </div>
  );
}
