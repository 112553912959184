import React, { useEffect, useState } from "react";
import axios from "axios";

export default function EngineeringGallery() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/gallery`);
        console.log("Gallery API Response:", response.data);

        if (!Array.isArray(response.data)) {
          throw new Error("Unexpected API response format");
        }

        const imagesData = response.data.map((image) => ({
          ...image,
          img: image.img.replace(/\\/g, "/"), // Replace backslashes with forward slashes
        }));

        setImages(imagesData);
      } catch (err) {
        console.error("Error fetching gallery images:", err);
        setError("Failed to load images. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [API_BASE_URL]);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const showNextImage = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const showPrevImage = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const handleClickOutside = (e) => {
    if (e.target.classList.contains("lightbox-backdrop")) {
      closeLightbox();
    }
  };

  if (loading) {
    return <div className="text-center py-8">Loading images...</div>;
  }

  if (error) {
    return <div className="text-center py-8 text-red-500">{error}</div>;
  }

  if (images.length === 0) {
    return (
      <div className="text-center py-8">
        <h2 className="text-2xl font-bold">No images found.</h2>
      </div>
    );
  }

  const imagePath = (image) =>
    image?.img
      ? `${API_BASE_URL}/uploads/main/${image.img}`
      : "/default-placeholder.webp";

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-center text-blue-600 text-sm uppercase tracking-wide mb-2">
        Engineering Excellence
      </h2>
      <h1 className="text-center text-3xl md:text-4xl font-bold text-gray-800 mb-8">
        Our Projects & Capabilities
      </h1>
      <p className="text-center text-gray-600 mb-8 max-w-2xl mx-auto">
        Explore our state-of-the-art projects and engineering solutions in
        action.
      </p>

      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={image._id || `gallery-image-${index}`}
            className="relative w-full cursor-pointer group"
            style={{ paddingTop: "56.25%" }}
            onClick={() => openLightbox(index)}
          >
            <img
              src={imagePath(image)}
              alt={image.alt || `Gallery Image ${index + 1}`}
              className="absolute inset-0 w-full h-full object-cover rounded-lg transition-transform duration-200 group-hover:scale-105"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/default-placeholder.webp";
              }}
              crossOrigin="anonymous"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-lg"></div>
          </div>
        ))}
      </div>

      {lightboxOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50 lightbox-backdrop"
          onClick={handleClickOutside}
        >
          <div className="relative max-w-3xl w-full">
            <img
              src={imagePath(images[currentIndex])}
              alt={`Lightbox Image ${currentIndex + 1}`}
              className="w-full h-auto rounded-lg"
            />
            <button
              onClick={closeLightbox}
              className="absolute top-4 right-4 text-white text-2xl font-bold"
            >
              &times;
            </button>
            <button
              onClick={showPrevImage}
              className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white bg-gray-800 bg-opacity-70 px-3 py-2 rounded-full text-lg"
            >
              &#8249;
            </button>
            <button
              onClick={showNextImage}
              className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white bg-gray-800 bg-opacity-70 px-3 py-2 rounded-full text-lg"
            >
              &#8250;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
