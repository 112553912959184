import React from 'react';

const AboutUs = () => {
  return (
    <div className="bg-gray-100 py-16 px-4">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-900 mb-6 text-center">
          Welcome to ABLETECH ENGINEERING
        </h1>

        <div className="bg-white shadow-lg rounded-lg p-8 md:p-12">
          <p className="text-lg text-gray-700 leading-relaxed mb-4">
            Since our inception in 2005, Able Tech Engineering has been at the forefront of designing, 
            manufacturing, exporting, and supplying high-performance heavy-duty heat exchangers and oil coolers. 
            Driven by a commitment to excellence, we aim to deliver robust and efficient industrial process equipment 
            that meets the diverse needs of our clients across various industries. Over the years, we have earned a 
            solid reputation in both national and international markets, recognized for our quality, innovation, and reliability.
          </p>

          <p className="text-lg text-gray-700 leading-relaxed mb-4">
            Our expertise spans the entire process—from concept design and engineering to fabrication and delivery—ensuring 
            that every product we create meets the highest standards of performance and durability. At Able Tech Engineering, 
            we take pride in offering a comprehensive range of heat exchangers, each tailored to meet specific customer requirements, 
            whether for small-scale operations or large industrial applications.
          </p>

          <p className="text-lg text-gray-700 leading-relaxed">
            Our dedication to customer satisfaction goes beyond just providing exceptional products. We also offer expert guidance 
            and technical support, helping our clients choose the most suitable solutions for their unique needs. With our cutting-edge 
            technology, skilled workforce, and unwavering commitment to quality, Able Tech Engineering is your trusted partner for all 
            your heat exchanger and oil cooler needs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
