import React from "react";

const industries = [
  {
    name: "Cement",
    image: "https://via.placeholder.com/400x250/E0B57C/333333?text=Cement+Industry", // Soft beige background, dark text
  },
  {
    name: "Steels",
    image: "https://via.placeholder.com/400x250/708090/FFFFFF?text=Steel+Industry", // Slate gray background, white text
  },
  {
    name: "Paper Mills",
    image: "https://via.placeholder.com/400x250/ADD8E6/333333?text=Paper+Mills", // Light blue background, dark text
  },
  {
    name: "Sugar",
    image: "https://via.placeholder.com/400x250/FDFD96/333333?text=Sugar+Industry", // Soft yellow background, dark text
  },
  {
    name: "IPP",
    image: "https://via.placeholder.com/400x250/C5E3BF/333333?text=IPP", // Light green background, dark text
  },
  {
    name: "Tyres",
    image: "https://via.placeholder.com/400x250/C2C5FF/333333?text=Tyre+Industry", // Light indigo background, dark text
  },
];



export default function IndustriesList() {
  return (
    <div className="container mx-auto px-4 py-12">
      <div className="flex flex-col items-center mb-8">
        <div className="w-1/4 h-px bg-gray-400 mb-4"></div>
        <h2 className="text-3xl font-bold text-center text-gray-900">
          Industries We Serve
        </h2>
        <div className="w-1/4 h-px bg-gray-400 mt-4"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {industries.map((industry, index) => (
          <div
            key={index}
            className={`flex flex-col items-center p-4 rounded-lg shadow-lg ${industry.bgColor}`}
          >
            <img
              src={industry.image}
              alt={`${industry.name} industry`}
              className="w-full h-64 object-cover rounded-lg mb-4"
            />
            <h3 className="text-xl font-semibold text-center text-gray-800">
              {industry.name}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
}
