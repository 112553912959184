import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function ContactForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_2arujwn', 'template_76rlm3w', form.current, 'U3R2MXJbs01Yf5rxR')
      .then(
        () => {
          console.log('SUCCESS!');
          alert('Message sent successfully!');
          // Clear the form after successful submission
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert('Failed to send message. Please try again later.');
        }
      );
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
     <div className="text-center mb-8">
  <h2 className="text-sm font-semibold text-gray-600 uppercase">CONTACT US</h2>
  <h1 className="text-3xl font-bold mt-2 mb-4">Let's Start a Conversation</h1>
  <p className="text-gray-600">
    Send us your detailed enquiry to the email addresses below, and we'll be in touch shortly.
  </p>
</div>

<div className="bg-purple-600 text-white p-6 mb-6 rounded-lg">
  <div className="flex justify-between">
    <div>
      <h3 className="font-semibold mb-2">ABLETECH ENGINEERING</h3>
      <p>S.F.NO.-483/1A2,#19, Vadivu Nagar,</p>
      <p>Chettipalayam Road, Vellalore PO, Podanur,</p>
      <p>Coimbatore-641111, Tamil Nadu, India</p>
      <p className="mt-4">Mobile: 09443117233, 9487932533</p>
      <p>Phone: +91-0422 2977233, 29977533</p>
    </div>
    <div>
      <h3 className="font-semibold mb-2">Email</h3>
      <p>info@abletechengineering.com</p>
      <p>sales@abletechengineering.com</p>
    </div>
  </div>
</div>


      <form ref={form} onSubmit={sendEmail}>
        <div className="mb-4">
          <input
            type="text"
            name="fullname"
            placeholder="Full Name"
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            name="phone"
            placeholder="Phone Number"
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <textarea
            name="message"
            placeholder="Message"
            rows="4"
            className="w-full p-2 border border-gray-300 rounded"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full bg-yellow-400 text-black font-semibold py-2 px-4 rounded hover:bg-yellow-500 transition duration-300"
        >
          Send Message
        </button>
      </form>
    </div>
  );
}
