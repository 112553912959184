import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { FiUser } from "react-icons/fi";

const testimonials = [
  {
    name: "Amit Verma",
    location: "Delhi, India",
    content:
      "Able Tech Engineering exceeded our expectations. Their precision in manufacturing and dedication to quality are unmatched. We highly recommend their services to anyone in need of reliable engineering solutions.",
  },
  {
    name: "Sophia Martinez",
    location: "Texas, USA",
    content:
      "The team at Able Tech Engineering delivered our project ahead of schedule without compromising on quality. Their professionalism and technical expertise are truly commendable.",
  },
  {
    name: "Rajesh Kumar",
    location: "Mumbai, India",
    content:
      "Their manufacturing facilities are state-of-the-art, and their after-sales support is exceptional. Able Tech Engineering is a partner we can trust for all our engineering needs.",
  },
  {
    name: "Elena Ivanova",
    location: "Moscow, Russia",
    content:
      "We have worked with many engineering firms, but Able Tech Engineering stands out for their attention to detail and innovative approach. Their team is responsive and always ready to help.",
  },
  {
    name: "Michael Johnson",
    location: "London, UK",
    content:
      "Able Tech Engineering provided us with customized solutions that perfectly matched our requirements. Their commitment to delivering quality products on time is unparalleled.",
  },
  {
    name: "Michael Johnson",
    location: "London, UK",
    content:
      "Able Tech Engineering provided us with customized solutions that perfectly matched our requirements. Their commitment to delivering quality products on time is unparalleled.",
  },
  {
    name: "Michael Johnson",
    location: "London, UK",
    content:
      "Able Tech Engineering provided us with customized solutions that perfectly matched our requirements. Their commitment to delivering quality products on time is unparalleled.",
  },
  {
    name: "Michael Johnson",
    location: "London, UK",
    content:
      "Able Tech Engineering provided us with customized solutions that perfectly matched our requirements. Their commitment to delivering quality products on time is unparalleled.",
  },
  {
    name: "Michael Johnson",
    location: "London, UK",
    content:
      "Able Tech Engineering provided us with customized solutions that perfectly matched our requirements. Their commitment to delivering quality products on time is unparalleled.",
  },
  {
    name: "Michael Johnson",
    location: "London, UK",
    content:
      "Able Tech Engineering provided us with customized solutions that perfectly matched our requirements. Their commitment to delivering quality products on time is unparalleled.",
  },
  {
    name: "Michael Johnson",
    location: "London, UK",
    content:
      "Able Tech Engineering provided us with customized solutions that perfectly matched our requirements. Their commitment to delivering quality products on time is unparalleled.",
  },
  {
    name: "Michael Johnson",
    location: "London, UK",
    content:
      "Able Tech Engineering provided us with customized solutions that perfectly matched our requirements. Their commitment to delivering quality products on time is unparalleled.",
  },
];



export default function TestimonialsOne() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialsPerPage = 3;

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % Math.ceil(testimonials.length / testimonialsPerPage));
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + Math.ceil(testimonials.length / testimonialsPerPage)) % Math.ceil(testimonials.length / testimonialsPerPage)
    );
  };

  return (
    <div className="bg-gradient-to-br from-gray-100 to-gray-200 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
          What Our Clients Say
        </h2>
        <div className="relative">
          <div
            className="flex overflow-hidden transition-transform duration-300"
            style={{ transform: `translateX(-${currentIndex * 100 / testimonialsPerPage}%)` }}
          >
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-full sm:w-1/2 lg:w-1/3 px-4"
              >
                <div className="bg-white rounded-lg shadow-md p-6 h-full flex flex-col items-center">
                  <div className="flex items-center justify-center w-16 h-16 bg-gray-200 text-gray-600 rounded-full mb-4">
                    <FiUser size={32} />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-800">{testimonial.name}</h3>
                  <p className="text-sm text-gray-500 mb-4">{testimonial.location}</p>
                  <p className="text-gray-600 text-center leading-relaxed line-clamp-3">
                    {testimonial.content}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="absolute inset-x-0 bottom-0 flex justify-center gap-4 mt-4">
            <button
              onClick={prevTestimonial}
              className="bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 transition focus:outline-none"
            >
              <ChevronLeft size={24} />
            </button>
            <button
              onClick={nextTestimonial}
              className="bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 transition focus:outline-none"
            >
              <ChevronRight size={24} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
