import React, { useState } from 'react'
import { X, ChevronDown, ChevronUp, Heart, Search, ShoppingBag, Menu } from 'lucide-react'

const products = [
  { id: 1, name: 'Women Shimmer Legging - Gold Dust', price: 'Rs. 749', image: '/placeholder.svg' },
  { id: 2, name: 'Women Cotton Churidar Legging - Mystic Pink', price: 'Rs. 599', image: '/placeholder.svg' },
  { id: 3, name: 'Women Cotton Ankle Legging - Blue Chip', price: 'Rs. 499', image: '/placeholder.svg' },
  { id: 4, name: 'Women Viscose Ankle Legging - Candle Light', price: 'Rs. 649', image: '/placeholder.svg' },
]

const subCategories = [
  'Ankle Leggings Cotton(1)',
  'Ankle Leggings Viscose(1)',
  'Bamboo Modal Ankle Legging(1)',
  'Bamboo Modal Churidar Legging(1)',
  'Capri Leggings(1)',
  'Churidar Leggings Cotton(1)',
  'Churidar Leggings Viscose(1)',
  'High Ankle Legging(1)',
]

const colors = [
  { name: 'Black', code: 'bg-black' },
  { name: 'Blue Chip', code: 'bg-blue-500' },
  { name: 'Candle Light', code: 'bg-yellow-300' },
  { name: 'Crimson Red', code: 'bg-red-600' },
  { name: 'Gold Dust', code: 'bg-yellow-600' },
  { name: 'La Burgandy', code: 'bg-red-800' },
  { name: 'Mystic Pink', code: 'bg-pink-400' },
]

const sortOptions = [
  'Featured',
  'Best selling',
  'Alphabetically, A-Z',
  'Alphabetically, Z-A',
  'Price, low to high',
  'Price, high to low',
  'Date, old to new',
  'Date, new to old',
]

export default function Shop() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isSortOpen, setIsSortOpen] = useState(false)
  const [selectedSort, setSelectedSort] = useState('Featured')

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <header className="bg-purple-900 text-white p-4 flex justify-between items-center">
        <div className="text-2xl font-bold">TWIN BIRDS</div>
        <div className="flex items-center space-x-4">
          <Search className="h-6 w-6" />
          <ShoppingBag className="h-6 w-6" />
          <Menu className="h-6 w-6 md:hidden" onClick={() => setIsSidebarOpen(true)} />
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-center">LEGGINGS</h1>

        <div className="flex flex-col md:flex-row gap-8">
          {/* Sidebar for desktop */}
          <aside className="w-full md:w-64 hidden md:block">
            <FilterSidebar />
          </aside>

          {/* Main content */}
          <div className="flex-1">
            {/* Mobile filter and sort */}
            <div className="flex justify-between items-center mb-4 md:hidden">
              <button
                className="bg-white px-4 py-2 rounded-md shadow"
                onClick={() => setIsSidebarOpen(true)}
              >
                Filter
              </button>
              <div className="relative">
                <button
                  className="bg-white px-4 py-2 rounded-md shadow flex items-center"
                  onClick={() => setIsSortOpen(!isSortOpen)}
                >
                  Sort
                  <ChevronDown className="ml-2 h-4 w-4" />
                </button>
                {isSortOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                    {sortOptions.map((option) => (
                      <button
                        key={option}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                        onClick={() => {
                          setSelectedSort(option)
                          setIsSortOpen(false)
                        }}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Product grid */}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {products.map((product) => (
                <div key={product.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                  <img src={product.image} alt={product.name} className="w-full h-64 object-cover" />
                  <div className="p-4">
                    <h3 className="font-semibold text-sm mb-2">{product.name}</h3>
                    <p className="text-gray-600">{product.price}</p>
                  </div>
                  <button className="absolute top-2 right-2 text-gray-500 hover:text-red-500">
                    <Heart className="h-6 w-6" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>

      {/* Mobile sidebar */}
      {isSidebarOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="absolute right-0 top-0 h-full w-80 bg-white p-4 overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">SIDEBAR</h2>
              <button onClick={() => setIsSidebarOpen(false)}>
                <X className="h-6 w-6" />
              </button>
            </div>
            <FilterSidebar />
          </div>
        </div>
      )}
    </div>
  )
}

function FilterSidebar() {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="font-semibold mb-2">FILTER</h3>
        <div className="space-y-4">
          <FilterSection title="SUB CATEGORY" items={subCategories} />
          <FilterSection title="PRICE">
            <div className="flex items-center space-x-2">
              <input type="text" placeholder="Rs. 0" className="w-20 p-2 border rounded" />
              <span>—</span>
              <input type="text" placeholder="Rs. 849" className="w-20 p-2 border rounded" />
            </div>
            <button className="mt-2 px-4 py-2 bg-black text-white rounded">FILTER</button>
          </FilterSection>
          <FilterSection title="COLOR">
            <div className="flex flex-wrap gap-2">
              {colors.map((color) => (
                <div key={color.name} className="flex items-center space-x-2">
                  <div className={`w-4 h-4 rounded-full ${color.code}`}></div>
                  <span>{color.name}</span>
                </div>
              ))}
            </div>
          </FilterSection>
        </div>
      </div>
    </div>
  )
}

function FilterSection({ title, items, children }) {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <div>
      <button
        className="flex justify-between items-center w-full font-semibold"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        {isOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
      </button>
      {isOpen && (
        <div className="mt-2 space-y-2">
          {items ? (
            items.map((item, index) => (
              <label key={index} className="flex items-center space-x-2">
                <input type="checkbox" className="rounded" />
                <span>{item}</span>
              </label>
            ))
          ) : (
            children
          )}
        </div>
      )}
    </div>
  )
}