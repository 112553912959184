import React from 'react';

export default function MapOne() {
  return (
    <div className="relative w-full h-[400px] overflow-hidden">
      {/* Google Map overlay */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d38887.46992848968!2d77.015475!3d10.939885!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85a0cacc378cb%3A0x7b9acc22224e8df5!2sABLE%20TECH%20ENGINEERING!5e1!3m2!1sen!2sus!4v1730081381592!5m2!1sen!2sus&zoom=21"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
