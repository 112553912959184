import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SliderOne() {
  const [sliders, setSliders] = useState([]); // State for slider data
  const [loading, setLoading] = useState(true); // Loading state
  const [currentSlide, setCurrentSlide] = useState(0); // Track the current slide
  const placeholderImage = '/default-placeholder.webp'; // Path to the placeholder image

  // Fetch sliders from backend
  useEffect(() => {
    const fetchSliders = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/slider`
        );
        console.log('Sliders API Response:', response.data); // Log response data
        const slidersData = Array.isArray(response.data) ? response.data : [];
        setSliders(slidersData); // Ensure sliders is always an array
      } catch (error) {
        console.error('Error fetching sliders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSliders();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: () => (
      <div className="w-2 h-2 bg-gray-300 rounded-full hover:bg-blue-500 transition" />
    ),
    appendDots: (dots) => (
      <div style={{ bottom: '10px' }}>
        <ul style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>{dots}</ul>
      </div>
    ),
    beforeChange: (_, next) => setCurrentSlide(next), // Update currentSlide on slide change
  };

  const imagePath = (img) =>
    img
      ? `${process.env.REACT_APP_BACKEND_API_URL}/uploads/main/${img.replace(/\\/g, '/')}`
      : placeholderImage;

  // Loader
  if (loading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  // Fallback for empty sliders
  if (sliders.length === 0) {
    return (
      <div className="text-center py-8">
        <h2 className="text-2xl font-bold">No Sliders Available</h2>
      </div>
    );
  }

  return (
    <div className="relative flex flex-col lg:flex-row w-full h-auto lg:h-[500px] bg-gray-100 overflow-hidden">
      {/* Slider Section */}
      <div className="lg:w-1/2 w-full h-[300px] lg:h-full overflow-hidden">
        <Slider {...settings}>
          {sliders.map((slider, index) => (
            <div key={index} className="relative w-full h-full">
              <img
                src={imagePath(slider.img)}
                alt={slider.title || 'Default Title'}
                crossOrigin="anonymous"
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src = placeholderImage; // Fallback to placeholder
                }}
                className="w-full h-full object-cover"
                style={{
                  objectFit: 'cover',
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
              />

              <div className="absolute inset-0 bg-gradient-to-r from-blue-900/80 to-blue-900/40"></div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Text Section */}
      <div className="lg:w-1/2 w-full flex items-center justify-center bg-white p-6 lg:p-10">
        <div>
          <h2 className="text-2xl lg:text-3xl font-bold mb-4">
            {sliders[currentSlide]?.title || 'Welcome!'}
          </h2>
          <p className="text-base lg:text-lg mb-6">
            {sliders[currentSlide]?.description ||
              'Explore our features, products, and services through this slider.'}
          </p>
          {sliders[currentSlide]?.buttonText && sliders[currentSlide]?.buttonLink && (
            <a
              href={sliders[currentSlide]?.buttonLink}
              className="bg-blue-600 text-white font-bold py-2 px-4 lg:px-6 rounded hover:bg-blue-500 transition duration-300"
            >
              {sliders[currentSlide]?.buttonText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default SliderOne;
