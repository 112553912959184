import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Shop from "./pages/Home/Shop";
import NewArrivals from "./NewArrivals";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/Home/AboutUs";
import SinglePage from "./pages/Home/SinglePage";
import Products from "./pages/Home/Products";
import Clients from "./pages/Home/Clients";
import IndustriesWeServe from "./pages/Home/IndustriesWeServe";
import Contact from "./pages/Home/Contact";
import Gallery from "./pages/Home/Gallery";
import LandingOne from "./pages/LandingPage/LandingOne";
import DetailPage from "./components/detail-page/DetailPage";
import CertificatePage from "./components/CertificatePage";
import Infrastructure from "./components/Infrastructure";
import Overseas from "./pages/Home/Overseas";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Main Layout Routes */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="shop" element={<Shop />} />
          <Route path="single-page" element={<SinglePage />} />
          <Route path="new-arrivals" element={<NewArrivals />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="overseas-partners" element={<Overseas/> } />
          <Route path="gallery" element={<Gallery />} />
          <Route path="products" element={<Products />} />
          <Route path="clients" element={<Clients />} />
          <Route path="certificate" element={<CertificatePage />} />
          <Route path="our-infrastructure" element={<Infrastructure />} />
          <Route path="industriesweserve" element={<IndustriesWeServe />} />
           {/* Dynamic Page Route */}
        <Route path="/:slug" element={<DetailPage />} />
        </Route>

        {/* Admin Routes */}
        <Route path="/landing-page" element={<LandingOne />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
