import React, { useState } from 'react'
import { Filter, X, ChevronDown, Heart, ShoppingCart, Menu } from 'lucide-react'

const products = [
  { id: 1, name: 'Women Shimmer Legging - Gold Dust', price: 749, image: '/placeholder.svg' },
  { id: 2, name: 'Women Cotton Churidar Legging - Mystic Pink', price: 599, image: '/placeholder.svg' },
  { id: 3, name: 'Women Cotton Ankle Legging - Blue Chip', price: 649, image: '/placeholder.svg' },
  { id: 4, name: 'Women Viscose Ankle Legging - Candle Light', price: 699, image: '/placeholder.svg' },
]

const filters = [
  { name: 'SUB CATEGORY', options: ['Ankle Leggings Cotton', 'Ankle Leggings Viscose', 'Bamboo Modal Ankle Legging', 'Bamboo Modal Churidar Legging', 'Capri Leggings', 'Churidar Leggings Cotton', 'Churidar Leggings Viscose', 'High Ankle Legging'] },
  { name: 'PRICE', options: [] },
  { name: 'COLOR', options: ['Black', 'Blue Chip', 'Candle Light', 'Crimson Red', 'Gold Dust', 'La Burgandy', 'Mystic Pink'] },
  { name: 'SIZE', options: ['S', 'M', 'L', 'XL'] },
]

export default function NewArrivals() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isSortOpen, setIsSortOpen] = useState(false)

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-2xl font-bold text-center my-4">LEGGINGS</h1>
      
      {/* Mobile Filter and Sort */}
      <div className="md:hidden flex justify-between items-center mb-4">
        <button onClick={() => setIsSidebarOpen(true)} className="flex items-center">
          <Filter className="w-5 h-5 mr-2" />
          Filter
        </button>
        <button onClick={() => setIsSortOpen(!isSortOpen)} className="flex items-center">
          Sort
          <ChevronDown className="w-5 h-5 ml-2" />
        </button>
      </div>

      {/* Sort Dropdown for Mobile */}
      {isSortOpen && (
        <div className="md:hidden bg-white shadow-md rounded-md p-4 mb-4">
          <h3 className="font-semibold mb-2">SORT BY:</h3>
          {['Featured', 'Best selling', 'Alphabetically, A-Z', 'Alphabetically, Z-A', 'Price, low to high', 'Price, high to low', 'Date, old to new', 'Date, new to old'].map((option) => (
            <div key={option} className="py-2">{option}</div>
          ))}
        </div>
      )}

      <div className="flex flex-col md:flex-row">
        {/* Sidebar for Desktop */}
        <div className="hidden md:block w-1/4 pr-4">
          <h2 className="text-xl font-semibold mb-4">FILTER</h2>
          {filters.map((filter) => (
            <div key={filter.name} className="mb-4">
              <h3 className="font-semibold mb-2">{filter.name}</h3>
              {filter.options.map((option) => (
                <div key={option} className="flex items-center mb-2">
                  <input type="checkbox" id={option} className="mr-2" />
                  <label htmlFor={option}>{option}</label>
                </div>
              ))}
              {filter.name === 'PRICE' && (
                <div>
                  <input type="range" min="0" max="849" className="w-full" />
                  <div className="flex justify-between">
                    <span>Rs. 0</span>
                    <span>Rs. 849</span>
                  </div>
                  <button className="mt-2 px-4 py-2 bg-black text-white rounded">FILTER</button>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Product Grid */}
        <div className="md:w-3/4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {products.map((product) => (
            <div key={product.id} className="border rounded-lg overflow-hidden">
              <img src={product.image} alt={product.name} className="w-full h-64 object-cover" />
              <div className="p-4">
                <h3 className="font-semibold mb-2">{product.name}</h3>
                <p className="text-gray-600">Rs. {product.price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Mobile Sidebar */}
      {isSidebarOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="bg-white h-full w-4/5 max-w-sm overflow-auto">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-semibold">FILTER</h2>
              <button onClick={() => setIsSidebarOpen(false)}>
                <X className="w-6 h-6" />
              </button>
            </div>
            <div className="p-4">
              {filters.map((filter) => (
                <div key={filter.name} className="mb-4">
                  <h3 className="font-semibold mb-2">{filter.name}</h3>
                  {filter.options.map((option) => (
                    <div key={option} className="flex items-center mb-2">
                      <input type="checkbox" id={`mobile-${option}`} className="mr-2" />
                      <label htmlFor={`mobile-${option}`}>{option}</label>
                    </div>
                  ))}
                  {filter.name === 'PRICE' && (
                    <div>
                      <input type="range" min="0" max="849" className="w-full" />
                      <div className="flex justify-between">
                        <span>Rs. 0</span>
                        <span>Rs. 849</span>
                      </div>
                      <button className="mt-2 px-4 py-2 bg-black text-white rounded w-full">FILTER</button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}