import axios from "axios";

// Function to retrieve the token and return the configuration object
export const config = () => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      Accept: "application/json",
    },
  };
};

// Create an axios instance using the environment variable for the base URL
const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL, // Environment variable for the backend URL
  withCredentials: true, // Include credentials for cross-origin requests, if needed
});

// Optional: Add interceptors for better error handling
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API Error:", error.response || error.message);
    return Promise.reject(error);
  }
);

export default instance;
