import React from "react";

import BreadCrumb from "../../components/BreadCrumb";
import CompanyLogoOne from "../../components/CompanyLogoOne";

const Clients = () => {
  return (
    <div>
      <BreadCrumb />
   
      <CompanyLogoOne />
    </div>
  );
};

export default Clients;
