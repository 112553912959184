import React from "react";

import CardSectionOne from "../../components/CardSectionOne";
import CardCarouselOne from "../../components/CardCarouselOne";
import InfoSection from "../../components/InfoSection";
import Banner from "../../components/home/Banner";
import SliderSectionOne from "../../components/home/SliderSectionOne";
import TestimonialsOne from "../../components/TestimonialsOne";
import CallToActionOne from "../../components/CallToActionOne";
import StepSectionOne from "../../components/StepSectionOne";
import SectionOne from "../../components/SectionOne";
import HappyCustomers from "../../components/HappyCustomers";
import MiniEnquiryOne from "../../components/MiniEnquiryOne";
import SliderOne from "../../components/home/SliderOne";
import ConvertOne from "../../components/ConvertOne";

const Home = () => {
  return (
    <div>
      {/* <Banner /> */}
      {/* done */}
      <SliderOne/>  {/* done */}
      {/* <SliderSectionOne />  */}
      <CardSectionOne />
      <CallToActionOne />
      <HappyCustomers />
      <SectionOne />
      <TestimonialsOne />
      {/* <StepSectionOne />
      <CardCarouselOne /> */}
      {/* <MiniEnquiryOne />
      <ConvertOne />
      <InfoSection /> */}
    </div>
  );
};

export default Home;
