import React from "react";

const Infrastructure = () => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        maxWidth: "900px",
        margin: "0 auto",
        lineHeight: "1.6",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          marginBottom: "20px",
          fontSize: "28px",
          color: "#2C3E50",
        }}
      >
        ⚙️ INFRASTRUCTURE
      </h1>

      {/* Designing Facilities Section */}
      <section style={{ marginBottom: "40px" }}>
        <h2
          style={{
            marginBottom: "10px",
            fontSize: "20px",
            color: "#34495E",
          }}
        >
          🛠 Designing Facilities
        </h2>
        <p>
          We design as per Customer Requirements. Thermal Design of Heat
          Exchangers is carried out on indigenously developed computer software
          to produce the most economical solution for the desired performance
          conditions.
        </p>
        <h3
          style={{
            marginTop: "20px",
            fontSize: "18px",
            color: "#4A4A4A",
          }}
        >
          Application:
        </h3>
        <ul style={{ marginLeft: "20px", listStyleType: "✔" }}>
          <li>
            Individually, each of our products is no more than a component.
            Carefully sized, selected, matched, and assembled into a system,
            together they can provide a solution to your application needs,
            whatever your industry.
          </li>
          <li>
            Whether your problem is refining oil, cooling milk, preparing a
            pharmaceutical product over a period of months, or supplying hot
            water to an entire hospital, we can find the answer among our
            experts and seasoned technical knowledge with reasonable charges.
          </li>
          <li>
            We are able to offer advice whether your installation is at the
            design stage, requiring maintenance, or in need of a major upgrade.
          </li>
        </ul>
        <p>
          Mechanical Design of Heat Exchangers / Pressure Vessels are carried
          out as per various construction codes like:
        </p>
        <ul style={{ marginLeft: "20px", listStyleType: "⬤" }}>
          <li>IS-2825</li>
          <li>TEMA</li>
          <li>IS-4503</li>
        </ul>
        <p>
          The design calculations are made with the help of indigenously
          developed computer software.
        </p>
      </section>

      {/* Manufacturing Facility Section */}
      <section>
        <h2
          style={{
            marginBottom: "10px",
            fontSize: "20px",
            color: "#34495E",
          }}
        >
          🏭 Manufacturing Facility
        </h2>
        <div
          style={{
            textAlign: "center",
            margin: "20px 0",
          }}
        >
          <img
            src="https://via.placeholder.com/500x300/6AB04C/FFFFFF?text=Manufacturing+Facility"
            alt="Factory"
            style={{
              width: "100%",
              maxWidth: "500px",
              height: "auto",
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
          />
        </div>
        <p>
          <strong>📍 OUR FACTORY LOCATION</strong>
        </p>
        <p>
          S.NO.483/1A2 ,<br />
          Site No.19, Vadivu Nagar,<br />
          Chettipalayam Road, Vellalore PO,<br />
          Coimbatore, Tamil Nadu, India.
        </p>
        <p>
          <strong>📊 Capacity:</strong> Approximately 3 to 5 Heat Exchangers /
          Oil Coolers per week.
        </p>
        <p>
          <strong>👷 Labour Force:</strong>
        </p>
        <ul style={{ marginLeft: "20px", listStyleType: "⬤" }}>
          <li>3 Supervisors</li>
          <li>13 Hourly Employees</li>
          <li>3 Code Qualified Welders</li>
        </ul>
        <p>
          <strong>🚚 Shipping:</strong>
        </p>
        <ul style={{ marginLeft: "20px", listStyleType: "✔" }}>
          <li>
            Ocean: Port of Chennai less than 510 Kms. from Albetech Facility.
          </li>
          <li>Truck: Serviced by all major carriers.</li>
          <li>Air: Coimbatore airport is about 5 Kms away from Albetech Facility.</li>
        </ul>
      </section>
    </div>
  );
};

export default Infrastructure;
