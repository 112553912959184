import React from 'react'
import OverseasPartners from '../../components/OverseasPartners'

const Overseas = () => {
  return (
    <div>
      <OverseasPartners/>
    </div>
  )
}

export default Overseas
