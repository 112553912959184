import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

// Use the base URL from the environment variables
const BASE_URL = process.env.REACT_APP_BACKEND_API_URL ;

export default function ProductsOne() {
  const [machines, setMachines] = useState([]); // State for storing machines data
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch pages from backend
  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/page/all`); // Fetch pages from backend
        const pages = response.data.map((page) => ({
          name: page.name,
          image: `${BASE_URL}/uploads/${page.thumbnail.replace(/\\/g, '/')}`, // Generate correct image path dynamically
          slug: page.slug, // Use `slug` for navigation
        }));
        setMachines(pages);
      } catch (error) {
        console.error('Error fetching pages:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPages();
  }, []);

  if (loading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        Major Machine Manufacturer in Bangalore
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {machines.map((machine, index) => (
          <Link
            to={`/${machine.slug}`} // Entire card is a clickable Link
            key={index}
            className="block border rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition duration-300"
          >
            {/* Product Image */}
            <img
  src={machine.image ? machine.image.replace(/\\/g, "/") : "/default-placeholder.webp"} // Normalize path and fallback to placeholder
  alt={machine.name || "Machine Image"} // Dynamic and meaningful alt text
  className="w-full h-48 object-cover"
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder.webp"; // Fallback to a placeholder image
  }}
  crossOrigin="anonymous" // Ensure compatibility for cross-origin requests
/>

            {/* Product Details */}
            <div className="p-4">
              <h2 className="text-lg font-semibold text-center">
                {machine.name}
              </h2>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
