import React from 'react';

const BreadCrumb = () => {
  return (
    <div className="relative w-full h-[250px]">
      {/* Background Color */}
      <div className="absolute inset-0 bg-gray-800">
        <div className="bg-gray-700 w-full h-full"></div>
      </div>

      {/* Text Content */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
        <h1 className="text-4xl font-bold">Our Clients</h1>
        {/* Breadcrumb */}
        <p className="text-sm mt-2">
          <span className="text-gray-300">Home</span> <span className="text-gray-400">›</span> <span>Our Clients</span>
        </p>
      </div>
    </div>
  );
};

export default BreadCrumb;
