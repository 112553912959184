import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;
const placeholderImage = "/default-placeholder.webp"; // Path to placeholder image

const OtherProducts = () => {
  const [products, setProducts] = useState([]); // State for products
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/page/all`);
        const productsData = response.data.map((product) => ({
          name: product.name || "Unnamed Product", // Fallback for missing name
          image: product.thumbnail
            ? `${BASE_URL}/uploads/${product.thumbnail.replace(/\\/g, "/")}`
            : placeholderImage, // Fallback for missing thumbnail
          slug: product.slug || "#", // Fallback for missing slug
        }));
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Display loader while fetching data
  if (loading) {
    return (
      <div className="text-center py-8">
        <svg
          className="animate-spin h-10 w-10 text-blue-500 mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
        <p className="mt-4 text-gray-500">Loading products...</p>
      </div>
    );
  }

  // Display message if no products are found
  if (products.length === 0) {
    return <div className="text-center py-8 text-gray-500">No products found.</div>;
  }

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Other Products</h2>
      <div className="space-y-4">
        {products.map((product, index) => (
          <div key={index} className="flex gap-4 items-center">
            <img
              src={product.image}
              alt={product.name}
              className="w-12 h-12 rounded object-cover"
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = placeholderImage; // Fallback to placeholder
              }}
              crossorigin="anonymous" // Ensure cross-origin compatibility
            />
            <div className="space-y-1">
              <h3 className="font-medium leading-snug text-gray-800">
                {product.name}
              </h3>
              <Link
                to={`/${product.slug}`}
                className="text-sm text-blue-600 hover:underline"
              >
                View Details
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherProducts;
