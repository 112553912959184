import React from "react";
import { FaSmile, FaProjectDiagram, FaGlobe } from "react-icons/fa"; // Import icons from React Icons

export default function HappyCustomers() {
  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
          Why Choose Able Tech Engineering?
        </h2>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-3">
          <StatItem
            icon={<FaSmile className="text-blue-600 w-16 h-16 mx-auto mb-4" />}
            number="500+"
            text="Satisfied Clients Worldwide"
          />
          <StatItem
            icon={<FaProjectDiagram className="text-blue-600 w-16 h-16 mx-auto mb-4" />}
            number="800+"
            text="Successful Projects Delivered"
          />
          <StatItem
            icon={<FaGlobe className="text-blue-600 w-16 h-16 mx-auto mb-4" />}
            number="25+"
            text="Countries Exported To"
          />
        </div>
      </div>
    </div>
  );
}

function StatItem({ icon, number, text }) {
  return (
    <div className="text-center">
      {/* Icon */}
      <div>{icon}</div>
      {/* Number */}
      <p className="text-5xl font-bold text-blue-600">{number}</p>
      {/* Description */}
      <p className="mt-2 text-xl text-gray-600">{text}</p>
    </div>
  );
}
