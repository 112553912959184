import React, { useState } from 'react';

const CertificatePage = () => {
  const [activeTab, setActiveTab] = useState(0);
  // Sample Certificate Data
  const certificates = [
    {
      id: 1,
      title: "CE Certificate",
      image: "./images/AbleTechEngineering_CE_Cert.jpg", // Replace with actual image URL
      text: "This certificate demonstrates the achievement of excellence in the relevant field."
    },
    {
      id: 2,
      title: "ISO Certificate",
      image: "./images/AbleTechISO-certificate.jpg", // Replace with actual image URL
      text: "This certificate is awarded for outstanding contributions to the industry."
    },
  ];

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <div
      style={{
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        maxWidth: '100vw',
        overflowX: 'hidden',
        textAlign: 'center',
      }}
    >
      {/* Tabs */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          marginBottom: '20px',
          flexWrap: 'wrap',
        }}
      >
        {certificates.map((cert, index) => (
          <button
            key={cert.id}
            onClick={() => handleTabChange(index)}
            style={{
              padding: '10px 20px',
              cursor: 'pointer',
              border: '1px solid #ccc',
              background: activeTab === index ? '#007bff' : '#f9f9f9',
              color: activeTab === index ? '#fff' : '#000',
              borderRadius: '5px',
              transition: 'all 0.3s ease',
              flexShrink: 0,
            }}
          >
            {cert.title}
          </button>
        ))}
      </div>

      {/* Certificate Display */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            width: '100%',
            maxWidth: '210mm', // A4 width
            aspectRatio: '210 / 297', // A4 aspect ratio
            border: '1px solid #000',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            background: '#fff',
            overflow: 'hidden',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={certificates[activeTab].image}
            alt={certificates[activeTab].title}
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </div>

        {/* Text Section */}
        <div
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            maxWidth: '210mm',
            textAlign: 'center',
            fontSize: '16px',
            color: '#333',
            backgroundColor: '#f9f9f9',
            borderRadius: '5px',
          }}
        >
          {certificates[activeTab].text}
        </div>
      </div>
    </div>
  );
};

export default CertificatePage;
