import React, { useState, useEffect, useRef } from "react";
import { Menu, X, ChevronDown, ChevronRight } from "lucide-react";
import axios, { config } from "../../utils/axiosConfig";
import ReactDOMServer from "react-dom/server";
import PopupOne from "../PopupOne";
import { base_url } from "../../utils/base_url";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false); // Mobile sidebar toggle
  const [showPopup, setShowPopup] = useState(false); // Popup visibility
  const [menuItems, setMenuItems] = useState([]); // Menu items fetched from API
  const [openDropdowns, setOpenDropdowns] = useState({}); // Dropdown toggle states
  const sidebarRef = useRef(null);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await axios.get(`${base_url}/api/menu`, config());
        setMenuItems(response.data);
  
        const renderedHTML = ReactDOMServer.renderToStaticMarkup(renderDesktopMenuItems(response.data));
        // console.log("Rendered Menu HTML:\n", renderedHTML);
      } catch (error) {
        console.error("Error fetching menu:", error);
      }
    };
    fetchMenu();
  }, []);

  const toggleSidebar = () => setIsOpen(!isOpen);
  const togglePopup = () => setShowPopup(!showPopup);

  const toggleDropdown = (id) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderDesktopMenuItems = (items, level = 0) => (
    <ul
      className={`${
        level === 0 ? "flex space-x-4" : "absolute bg-gray-100 shadow-lg rounded w-48 z-50"
      }`}
      style={{
        ...(level === 1 && { top: "100%", left: "0" }),
        ...(level > 1 && { top: "0", left: "100%" }),
      }}
    >
      {items.map((item) => {
        const hasChildren = item.children && item.children.length > 0;
        const isOpen = openDropdowns[item._id] || false;

        return (
          <li
            key={item._id}
            className="relative group"
            onMouseEnter={() => hasChildren && toggleDropdown(item._id)}
            onMouseLeave={() => hasChildren && toggleDropdown(item._id)}
          >
            <a
              href={item.link || "#"}
              className="flex items-center px-4 py-2 text-gray-700 hover:bg-blue-500 hover:text-white rounded transition-colors duration-200"
            >
              <span>{item.name}</span>
              {hasChildren &&
                (level === 0 ? (
                  <ChevronDown className="ml-2 h-4 w-4" />
                ) : (
                  <ChevronRight className="ml-2 h-4 w-4" />
                ))}
            </a>
            {hasChildren && isOpen && renderDesktopMenuItems(item.children, level + 1)}
          </li>
        );
      })}
    </ul>
  );

  const renderMobileMenuItems = (items, level = 0) => (
    <ul className={`${level === 0 ? "space-y-2" : "pl-4"}`}>
      {items.map((item) => {
        const hasChildren = item.children && item.children.length > 0;
        const isOpen = openDropdowns[item._id] || false;

        return (
          <li key={item._id} className="relative">
            <div className="flex justify-between items-center w-full px-4 py-2 rounded">
              {/* Clickable text for navigation */}
              <a
                href={item.link || "#"}
                className={`flex-grow ${
                  isOpen ? "text-white bg-gray-600" : "text-gray-100 bg-gray-700"
                } ${level > 0 ? "bg-gray-800" : ""}`}
              >
                {item.name}
              </a>
              {/* Arrow for expanding/collapsing the dropdown */}
              {hasChildren && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleDropdown(item._id);
                  }}
                  className="ml-2 text-white"
                >
                  {isOpen ? (
                    <ChevronDown className="h-4 w-4" />
                  ) : (
                    <ChevronRight className="h-4 w-4" />
                  )}
                </button>
              )}
            </div>
            {/* Render children if open */}
            {hasChildren && isOpen && renderMobileMenuItems(item.children, level + 1)}
          </li>
        );
      })}
    </ul>
  );

  return (
    <div>
      <header className="bg-white shadow-md p-4">
        <div className="container mx-auto flex justify-between items-center">
          {/* Logo and Company Name */}
          <div className="flex items-center">
            <a href="/" className="flex items-center">
              <img
                src="./images/logo.png"
                alt="Logo"
                className="mr-2"
                style={{ height: "80px", width: "130px" }}
              />
              <h1 className="text-lg font-semibold text-gray-800 hover:text-blue-600 transition-colors">
                Able Tech Engineering
              </h1>
            </a>
          </div>
          {/* Desktop Menu */}
          <div className="hidden lg:flex items-center space-x-4">
            <nav>{renderDesktopMenuItems(menuItems)}</nav>
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition-colors duration-200"
              onClick={togglePopup}
            >
              Enquiry Now
            </button>
          </div>
          {/* Mobile Menu Toggle */}
          <button onClick={toggleSidebar} className="lg:hidden">
            <Menu className="h-6 w-6" />
          </button>
        </div>
      </header>
      {/* Mobile Sidebar */}
      <div
        ref={sidebarRef}
        className={`fixed top-0 left-0 h-full w-64 bg-gray-800 text-white z-50 transform transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } lg:hidden`}
      >
        <div className="p-4 flex justify-between items-center border-b border-gray-300">
          <h2 className="text-xl font-bold">Menu</h2>
          <button onClick={toggleSidebar}>
            <X className="h-6 w-6" />
          </button>
        </div>
        <nav className="mt-4 sidebar-scroll max-h-[80vh] overflow-y-auto">
          {renderMobileMenuItems(menuItems)}
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition-colors duration-200 w-full mt-4"
            onClick={togglePopup}
          >
            Enquiry Now
          </button>
        </nav>
      </div>
      {showPopup && <PopupOne />}
    </div>
  );
}
